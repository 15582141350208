@import "../../styles/partials/functions";


.apply-score-set-modal-content {
  margin-top: getPxValue( 4 );
  height: 400px;
  
  .filter-box {  
    h4 {
      font-size: 0.75rem;
      font-weight: $fontWeightBold;
      line-height: 2em;
      text-transform: uppercase;
    }

    .ground-text-input-wrapper {
      .status-label {
        display: none;
      }
    }
    
    .ground-text-input {
      &::placeholder {
        font-size: 0.875rem;
        opacity: 0.5;
      }
    }
  }

  .radios-wrapper {
    $gap: getPxValue( 4 );

    column-gap: $gap;
    display: grid;
    grid-template-columns: repeat( 2, calc( 50% - calc( #{$gap} / 2 ) ) );
    overflow-y: auto;
  }

  .radio-box {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    padding: #{getPxValue( 1.5 )};
    padding-left: 0;

    .label {
      font-size: 0.875rem;
      word-break: break-all;
    }
  }
  .fleet-not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: getPxValue(6);
  }
  
  .fleet-not-found h2 {
    margin-bottom: getPxValue(2);
  }
  
  .fleet-not-found p {
    text-align: center;
  }
 
}