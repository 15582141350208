@import "../../../styles/partials/functions";

.textinput-wrapper {
  position: relative;

  .ground-text-input-wrapper.space-letters {
    .password {
      letter-spacing: 0.5rem;
    }
  }

  .validation-error {
    $fontSize: 0.75rem;

    bottom: calc( -1 * ( $fontSize + getPxValue( 1 ) ) );
    font-size: $fontSize;
    left: 0;
    line-height: 1;
    margin-left: getPxValue( 2 );
    position: absolute;
    text-align: left;

    a {
      color: inherit;
      text-decoration: underline;
    }
  }
}

.tooltip {
  position: relative;
  display: inline-block;

  &:hover {
    .tooltip-text,
    .tooltip-bottom {
      visibility: visible;
      opacity: 1;
    }
  }

  .tooltip-text,
  .tooltip-bottom {
    opacity: 0;
    position: absolute;
    transition: opacity 0.3s;
    visibility: hidden;
    z-index: 1;
  }
  .tooltip-text {
    background-color: $white;
    border-radius: 6px;
    bottom: 135%;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    color: $danger;
    font-size: 14px;
    left: 0%;
    padding: 8px 16px;
    text-align: center;
    width: max-content;
  }
  .tooltip-bottom {
    border-left: 10px solid transparent;
    border-right: 11px solid transparent;
    border-top: 14px solid $white;
    bottom: 100%;
    left: 40%;
    text-align: center;
  }
}