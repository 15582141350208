@import "../../styles/partials/functions";
@import "../../styles/partials/variables";

$eventLabelItemWidth: getPxValue(26);
$activeCheckboxItemWidth: getPxValue(7);

.new-score-container {
	padding-left: getPxValue( 3 );
	padding-right: getPxValue( 3 );

	.title-wrapper {
		h3 {
			font-size: 1.125rem;
		}

		.page-title {
			$width: calc(#{$eventLabelItemWidth} + #{$activeCheckboxItemWidth});
			text-transform: uppercase;

			min-width: $width;
			width: $width;
		}

		.severity-titles h3 {
			font-weight: $fontWeightRegular;
			text-align: center;
		}
	}

	.event-label-item {
		min-width: $eventLabelItemWidth;
		width: $eventLabelItemWidth;
	}

	.active-checkbox-item {
		min-width: $activeCheckboxItemWidth;
		width: $activeCheckboxItemWidth;
	}

	.controls-set-item {
		border-left-width: 1px;
		border-left-style: solid;
		border-left-color: transparent;
		box-sizing: border-box;
		padding: 0 #{getPxValue(2)};

		&:last-of-type {
			padding-right: 0;
		}
	}

	.headings-wrapper {
		border-bottom-width: 1px;
		border-bottom-style: solid;
		padding-bottom: getPxValue(0.5);

		h4 {
			font-size: 0.75rem;
			font-weight: $fontWeightRegular;
			line-height: 1.75em;
			text-align: center;
			text-transform: uppercase;
		}

		& > h4:first-of-type {
			text-align: left;
		}

		.controls-set-item h4 {
			// 2 / 3 => 2 gaps / 3 items
			$width: calc(33.33% - calc(#{getPxValue()} * 2 / 3));

			border: 1px solid transparent;
			margin-left: getPxValue(0.5);
			min-width: $width;
			width: $width;

			&:first-of-type {
				margin-left: 0;
			}
		}
	}

	.score-row {
		border-bottom-width: 1px;
		border-bottom-style: solid;
		padding: 0  #{getPxValue(1)};
		:first-child {
			align-items: center;
			strong {
				width: 150px;
			}
		}

		&.disabled{
			background-color: $monochromeGreyLight;

		}

		.ground-checkbox {
			margin: 0;
		}

		.ground-text-input-wrapper {
			.status-label {
				display: none;
			}
		}

		.ground-text-input {
			font-weight: $fontWeightBold;
			padding-left: getPxValue();
			padding-right: getPxValue();
			text-align: center;

			&::placeholder {
				font-size: 0.875rem;
				font-weight: $fontWeightRegular;
				opacity: 0.5;
			}
		}

		.event-label-item {
			font-size: 1.125rem;
		}

		.controls-set-item {
			margin: #{getPxValue(1)+1} 0;
		}

		.crash-label {
			text-transform: uppercase;
		}
	}
}
