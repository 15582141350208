@import "../../styles/partials/functions";


.save-score-set-modal-content {
  .ground-text-input-wrapper {
    .status-label {
      display: none;
    }
  }
  
  .ground-text-input {
    &::placeholder {
      font-size: 0.875rem;
      opacity: 0.5;
    }
  }
}