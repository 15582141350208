@import "../../styles/partials/functions";

@keyframes modal-appear {
  from {
    opacity: 0;
    transform: scale(.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.confirmation-modal {
  border-radius: $borderRadiusBox;
  box-sizing: border-box;
  min-height: getPxValue( 30 );
  padding: getPxValue( 3 );
  position: relative;

  &.small {
    width: $modalWindowSmallWidth;
  }

  &.medium {
    width: $modalWindowMediumWidth;
  }

  &.large {
    width: $modalWindowLargeWidth;
  }

  h3, h4 {
    font-size: 1.125rem;

    h3 {
      text-transform: uppercase;
    }

    h4 {
      font-weight: $fontWeightRegular;
    }
  }

  // Here's the animation:
  animation: modal-appear 0.3s ease-out;
}
