html {
  height: 100%;
}

body,
#root {
  display: flex;
  flex-direction: column;
  min-height: max( $bodyMinHeight, 100vh );
  min-width: $bodyMinWidth;
}


// --- Reusable Classes

.flex {
  display: flex;
}

.flex-inline {
  display: inline-flex;
}

.flex-rows {
  flex-direction: row;
}

.flex-rows-reverse {
  flex-direction: row-reverse;
}

.flex-columns {
  flex-direction: column;
}

.flex-columns-reverse {
  flex-direction: column-reverse;
}

.flex-center {
  align-items: center;
  justify-content: center;
}

.flex-vcenter {
  align-items: center;
}

.flex-hcenter {
  justify-content: center;
}

.flex-left {
  justify-content: flex-start;
}

.flex-right {
  justify-content: flex-end;
}

.flex-bottom {
  align-items: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-grow {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

.height-100 {
  height: 100%;
}

.height-100v {
  height: 100vh;
}

.width-100 {
  width: 100%;
}

.zindex-10 {
  z-index: 10;
}

.pos-relative {
  position: relative;
}

.pos-absolute {
  position: absolute;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.hidden {
  visibility: hidden;
}

.gap-1\/2 {
  gap: getPxValue( 0.5 );
}

.gap-1 {
  gap: getPxValue();
}

.gap-2 {
  gap: getPxValue( 2 );
}

.gap-3 {
  gap: getPxValue( 3 );
}

.gap-4 {
  gap: getPxValue( 4 );
}

.gap-5 {
  gap: getPxValue( 5 );
}

.gap-6 {
  gap: getPxValue( 6 );
}

.gap-7 {
  gap: getPxValue( 7 );
}

.mt-1 {
  margin-top: getPxValue();
}

.mt-2 {
  margin-top: getPxValue( 2 );
}

.mt-3 {
  margin-top: getPxValue( 3 );
}

.mt-4 {
  margin-top: getPxValue( 4 );
}

.mt-5 {
  margin-top: getPxValue( 5 );
}

.mb-1 {
  margin-bottom: getPxValue();
}

.mb-2 {
  margin-bottom: getPxValue( 2 );
}

.mb-3 {
  margin-bottom: getPxValue( 3 );
}

.mb-4 {
  margin-bottom: getPxValue( 4 );
}

.mb-5 {
  margin-bottom: getPxValue( 5 );
}

.ml-1 {
  margin-left: getPxValue();
}

.ml-2 {
  margin-left: getPxValue( 2 );
}

.ml-3 {
  margin-left: getPxValue( 3 );
}

.ml-4 {
  margin-left: getPxValue( 4 );
}

.ml-5 {
  margin-left: getPxValue( 5 );
}

.mr-1 {
  margin-right: getPxValue();
}

.mr-2 {
  margin-right: getPxValue( 2 );
}

.mr-3 {
  margin-right: getPxValue( 3 );
}

.mr-4 {
  margin-right: getPxValue( 4 );
}

.mr-5 {
  margin-right: getPxValue( 5 );
}

.mt-1 {
  margin-top: getPxValue();
}

.mt-2 {
  margin-top: getPxValue( 2 );
}

.mt-3 {
  margin-top: getPxValue( 3 );
}

.mt-4 {
  margin-top: getPxValue( 4 );
}

.mt-5 {
  margin-top: getPxValue( 5 );
}

.mb-1 {
  margin-bottom: getPxValue();
}

.mb-2 {
  margin-bottom: getPxValue( 2 );
}

.mb-3 {
  margin-bottom: getPxValue( 3 );
}

.mb-4 {
  margin-bottom: getPxValue( 4 );
}

.mb-5 {
  margin-bottom: getPxValue( 5 );
}

.pl-1 {
  padding-left: getPxValue();
}

.pl-2 {
  padding-left: getPxValue( 2 );
}

.pl-3 {
  padding-left: getPxValue( 3 );
}

.pl-4 {
  padding-left: getPxValue( 4 );
}

.pl-5 {
  padding-left: getPxValue( 5 );
}

.pr-1 {
  padding-right: getPxValue();
}

.pr-2 {
  padding-right: getPxValue( 2 );
}

.pr-3 {
  padding-right: getPxValue( 3 );
}

.pr-4 {
  padding-right: getPxValue( 4 );
}

.pr-5 {
  padding-right: getPxValue( 5 );
}

.pt-1 {
  padding-top: getPxValue();
}

.pt-2 {
  padding-top: getPxValue( 2 );
}

.pt-3 {
  padding-top: getPxValue( 3 );
}

.pt-4 {
  padding-top: getPxValue( 4 );
}

.pt-5 {
  padding-top: getPxValue( 5 );
}

.pb-1 {
  padding-bottom: getPxValue();
}

.pb-2 {
  padding-bottom: getPxValue( 2 );
}

.pb-3 {
  padding-bottom: getPxValue( 3 );
}

.pb-4 {
  padding-bottom: getPxValue( 4 );
}

.pb-5 {
  padding-bottom: getPxValue( 5 );
}