@import "../../../styles/partials/functions";

.password-recovery-form {
	.form-controls {
		.error-password-message {
			$minHeight: getPxValue(3);

			box-sizing: border-box;
			margin-top: -1 * $minHeight;
			min-height: $minHeight;
			padding-bottom: getPxValue(1);
		}
	}
}
