@import "../../styles/partials/functions";


$eventLabelItemWidth: getPxValue( 38 );

.view-score-modal-content {
  .event-label-item {
		min-width: $eventLabelItemWidth;
		width: $eventLabelItemWidth;
	}

	.values-set-item {
    $sidePadding: getPxValue();
    $width: calc( 33.33% - calc( #{$eventLabelItemWidth} / 3 ) );

    border-left-width: 1px;
		border-left-style: solid;
		box-sizing: border-box;
		padding: 0 #{$sidePadding};
    min-width: $width;
		width: $width;

    .value-item {
      $width: calc( 33.33% - calc( #{$sidePadding} * 2 / 3 ) );

      min-width: $width;
      width: $width;
    }
	}

	.headings-wrapper {
		border-bottom-width: 1px;
		border-bottom-style: solid;
		padding-bottom: getPxValue( 0.5 );
		
		h4 {
			font-size: 0.75rem;
			font-weight: $fontWeightRegular;
			line-height: 1.75em;
			text-align: center;
			text-transform: uppercase;
		}

		& > h4:first-of-type {
			text-align: left;
		}

		.values-set-item {
      border-left-color: transparent;
    }
	}

  .score-row {
		border-bottom-width: 1px;
		border-bottom-style: solid;

		.event-label-item {
			font-size: 1.125rem;
		}

		.values-set-item {
			margin: 8px 0;
		}

    .value-item {
      line-height: 2.1rem;
      text-align: center;
    }

		.crash-label {
			text-transform: uppercase;
		}
	}
}