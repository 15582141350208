@import "../../styles/partials/functions";

.score-set-list-wrapper {
	min-width: $sidebarWidth;
	padding-left: #{getPxValue(2.5)};
	padding-right: #{getPxValue(2.5)};
	width: $sidebarWidth;

	.header {
		border-bottom-width: 1px;
		border-bottom-style: solid;
		padding-bottom: getPxValue( 3 );

		h3 {
			text-transform: uppercase;
		}

		.toggle-button {
			width: 32px;
			height: 32px;
			border-radius: $borderRadiusBox;
		}
	}

	.inner-wrapper {
		&.scroll-content {
			overflow-y: auto;
			overflow-x: hidden;
		}


		.hide {
			display: none;
		}

		.list-item {
			border-top-style: solid;
			border-top-width: 1px;
			margin-right: #{getPxValue( .25 )};
			padding-bottom: #{getPxValue( 2 )};
			padding-left: #{getPxValue( .5 )};
			padding-right:#{getPxValue( .5 )};
			padding-top: #{getPxValue( 2 )};

			.list-item-checkbox-and-name {
				max-width: 67%;

				span {

					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}

			&:first-of-type {
				border-top: none;
			}

			&.is-default-score {
				background-color: $monochromeGreyLight;
			}
		}
	}

	.buttons-wrapper {
		border-top-width: 1px;
		border-top-style: solid;
		padding-top: getPxValue( 3 );
		flex-wrap: wrap;
		justify-content: center;

		.scoreset-list-button{
			width: calc(33.3% - 8px);
		}
		.filters-button{
			width: calc(50% - 8px);
		}
	}
}