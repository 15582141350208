@import "../../styles/partials/functions";

.tabbed-chart-container {
	display: flex;
	flex-direction: column;
	row-gap: #{getPxValue(2)};

	.tabbed-chart-tabs {
		display: flex;
		flex-direction: row;
		column-gap: #{getPxValue(1)};
		border-bottom: 1px solid $turquoise;

		button {
			border-radius: 4px 4px 0px 0px;
			border-bottom: none;

			&:focus {
				box-shadow: none;
			}

			&.selected,
			&:hover {
				background-color: $turquoise;
				border-color: $turquoise;
				color: $white;
			}
		}
	}
}