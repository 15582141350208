// Base Font Size
$bodyFontSize: 16px;

// Base Space
$baseSpace: 8px;

// Structure
$bodyMinWidth: 1450px;
$bodyMinHeight: 800px;
$headerHeight: 82px;
$footerHeight: 24px;
$mainContentTopPad: $baseSpace * 5;
$mainContentRightPad: $baseSpace * 4;
$mainContentBottomPad: $baseSpace * 4;
$mainContentLeftPad: $baseSpace * 4;
$mainContentHeight: 780px;
$sidebarWidth: 350px;

// Login
$loginBoxMinHeight: 570px;
$loginBoxWidth: 450px;
$loginBoxHeaderHeight: 150px;

// Modals
$modalMenuWidth: 230px;
$modalMenuButtonHeight: 48px;
$modalWindowSmallWidth: 450px;
$modalWindowMediumWidth: 650px;
$modalWindowLargeWidth: 980px;
$modalDrawerWidth: 430px;
$modalDrawerCloseBtnWidth: 42px;
$modalDrawerCloseBtnMinHeight: 32px;

// Typography
$bodyLineHeight: 1.25em; // 20px
$fontWeightRegular: 400;
$fontWeightMedium: 500;
$fontWeightBold: 700;
$fontFamilyPrimary: 'Roboto', sans-serif;
$fontSizeH2: 1.5rem;
$fontSizeH3: 1.125rem;

// Z-index
$zindexPopover: 10;
$zindexModal: 1000;
$zindexModalWindow: 3000;

// Box Shadow
$boxShadowHeader: 0px 0px 20px #353B401A;
$boxShadowModalMenu: 0px 2px 4px #0E263629;

// Border Radius
$borderRadiusBox: 8px;
$borderRadiusInput: 4px;

// Colors
$danger: #DD0546;
$white: #FFFFFF;
$monochromeGreyLight: #E1E1E1;
$monochromeGreyDarker: #BDBDBD;
$turquoise: #20A8BF;