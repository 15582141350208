@import "../../styles/partials/functions";


.main-content-wrapper {
  &.dark {
    .main-content {
      background: none;
    }
  }
}

.main-content {
  background: linear-gradient( #00000030, #ffffff00 50px );
  padding-bottom: $mainContentBottomPad;
  padding-left: $mainContentLeftPad;
  padding-right: $mainContentRightPad;
  padding-top: $mainContentTopPad;
}