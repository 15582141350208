@import "../../styles/partials/functions";


.modal-menu {
  border-radius: $borderRadiusBox;
  box-shadow: $boxShadowModalMenu;
  display: none;
  position: absolute;
  width: $modalMenuWidth;
  z-index: $zindexPopover;

  &.opened {
    display: flex;
    flex-direction: column;
  }

  .action-button {
    font-size: 0.875rem;
    font-weight: $fontWeightMedium;
    font-family: inherit;
    height: $modalMenuButtonHeight;
    padding: 0 #{getPxValue( 2 )};
    text-align: left;
  }
}