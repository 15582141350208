@import "../../styles/partials/functions";

.main-header {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  box-sizing: border-box;
  height: $headerHeight;
  padding: 0 #{getPxValue( 4 )};

  a {
    color: inherit;
  }

  .nav-item {
    border-bottom: 2px solid transparent;
    font-size: 1.125rem;
    padding: #{getPxValue( 1 )} #{getPxValue( 3 )};
    transition: border-bottom-color 0.3s;
  }

  .user-info-wrapper {
    position: relative;
    padding-left: getPxValue( 2 );
  }

  .modal-menu-wrapper {
    &.opened {
      .arrow {
        margin-top: 0.2rem;
        transform: none;
      }
    }

    .trigger {
      display: flex;
      gap: getPxValue();
      text-align: left;  
    }
  }

  .user-info {
    display: flex;
    flex-direction: column;
    gap: getPxValue( 0.25 );
    margin-left: getPxValue();
  }

  .user-fullname {
    font-size: 1rem;
    white-space: nowrap;
    max-width: getPxValue( 20 );
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .user-role {
    font-size: 0.75rem;
    text-transform: uppercase;
  }

  .arrow {
    margin-top: 0.4rem;
    transform: rotate( 180deg );
  }

  .modal-menu {
    right: getPxValue( -2 );
    top: 130%;
  }

  .lang-buttons-wrapper {
    border-bottom-style: solid;
    border-bottom-width: 1px;;
    padding: getPxValue( 2 );
    gap: getPxValue( 1.5 );

    .lang-button {
      &.inactive:not(:hover) {
        opacity: 50%;
      }

      svg {
        display: block;
      }

      &:active,
      &:focus {
        box-shadow: none;
      }
    }
  }
}