@import "../../../styles/partials/functions";


.filter-view-content {

	.ground-text-input,
	.ground-date-range-picker-input {
		font-size: 0.875rem;
		font-weight: $fontWeightMedium;

		&::placeholder {
			font-weight: $fontWeightRegular;
			opacity: 0.5;
		}
	}

	.checkbox-with-status-container {
		display: flex;
		flex-direction: row;
		column-gap: 4px;
		align-items: center;
		.ground-checkbox-wrapper {
			&.checked {
				.label-text {
					font-weight: $fontWeightMedium;
				}
			}
		}
	}
}